import React, { useEffect, useRef } from 'react';
import { Link, navigate, withPrefix } from 'gatsby';
import cx from 'classnames';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import useBenefitsPageQueries from 'src/components/benefits/useBenefitsPageQueries';
import isBrowser from 'src/helpers/isBrowser';
import useJoinTheClubLink from 'src/hooks/useJoinTheClubLink';
import DigitalBenefitCard, { MembershipCard } from 'src/components/DigitalBenefitCard';
import FadeIn from 'src/components/FadeIn';
import { Translate } from 'src/components/Translate';
import LoadingContainer from 'src/components/LoadingContainer';
import { WelcomeModal } from 'src/components/PurchaseFlowOptimization';
import callIcon from '../../../static/digital-benefits-page/call.svg';
import downloadIcon from '../../../static/digital-benefits-page/download.svg';
import bannerBackground from '../../../static/benefits-page/banner_car_vent.png';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { MainFooter } from 'src/features/footer';
import { Sidekick } from '@hagerty/react-components';
import { RoadsideModalBody, RoadsideModalFooter } from 'src/features/benefits-modal';

import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { isReady } from 'src/components/SplitContext/isReady';
import { Split, Treatment } from 'src/constants/Split';
import { useRequireAuthenticated } from 'src/components/AuthProvider';

const DigitalBenefits = () => {
  usePreventAgentAccess();
  useRequireAuthenticated();
  const linkTo = useJoinTheClubLink();

  useTracking('benefits_view');
  const { trackInteraction } = useTrackingContext();
  const { isShelbyCustomer } = useShelbyCustomer();

  const { ready, isAuthenticated, profile, membership, hasLegacyMembership } = useBenefitsPageQueries();

  const splitIsReady = isReady();
  const treatments = useTreatments([
    Split.FeatureBenefitsPage,
    Split.FeatureAskHagerty,
    Split.FeatureMemberFeedbackSurvey,
    Split.FeatureMemberVoting,
    Split.FeatureDigitalMagazineLink,
    Split.FeatureHdcReferrals,
  ]);
  const hasFeatureBenefitsPage = treatments[Split.FeatureBenefitsPage].treatment === Treatment.On;
  const hasAskHagertyFeature = treatments[Split.FeatureAskHagerty].treatment === Treatment.On;
  const hasMemberFeedbackSurvey = treatments[Split.FeatureMemberFeedbackSurvey].treatment === Treatment.On;
  const hasMemberVoting = treatments[Split.FeatureMemberVoting].treatment === Treatment.On;
  const hasMagazineLink = treatments[Split.FeatureDigitalMagazineLink].treatment === Treatment.On;
  const hasHdcReferral = treatments[Split.FeatureHdcReferrals]?.treatment === Treatment.On;

  const staticContent = [
    {
      image: '/digital-benefits-page/member.jpg',
    },
    {
      title: 'Roadside services',
      desc: 'Roadside tailored to fit your collector car needs.',
      linkText: 'Get assistance',
      link: '',
      isAppLink: hasHdcReferral ? false : true,
      image: '/digital-benefits-page/roadside.jpg',
      modalRef: 5,
      cypressData: 'benefits-roadside',
      roadsideManualUrl:
        'https://assets.ctfassets.net/w61n0jg6cdlz/1dCTkaSaaAW3e7TslPS08b/e98fe95a52437d15c5e10d1a0aae3bb3/22-5334-US-HDC-Roadside-Service-Manual-8.5x11.pdf',
      isReferral: false,
      roadsideContent: {
        isRoadside: true,
        linkText: 'Get Help Now',
        linkHref: 'https://hagerty.rsahelp.com/',
        buttonText: 'Learn More',
      },
      hasModal: hasHdcReferral,
      modalContent: {
        image: {
          url: withPrefix('/digital-benefits-page/roadside.jpg'),
          alt: 'Car enthusiasts',
        },
        title: 'Roadside services',
        body: <RoadsideModalBody />,
        footer: <RoadsideModalFooter />,
      },
    },
    {
      title: hasFeatureBenefitsPage ? 'Events and experiences' : 'Members-only experiences',
      desc: 'Sign up for exclusive events and livestreams.',
      linkText: 'Find events',
      link: hasFeatureBenefitsPage ? '/events/' : withPrefix('/events/'),
      isAppLink: hasFeatureBenefitsPage ? true : false,
      image: '/digital-benefits-page/events.jpg',
      modalRef: 8,
      cypressData: 'benefits-events',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
    {
      title: hasFeatureBenefitsPage ? 'Partner offers' : 'Automotive offers',
      desc: 'Check out the latest exclusive deals and offers.',
      linkText: 'See all offers',
      link: hasFeatureBenefitsPage ? '/partner-offers/' : withPrefix('/partner-offers/'),
      isAppLink: hasFeatureBenefitsPage ? true : false,
      image: '/digital-benefits-page/offers.jpg',
      modalRef: 10,
      cypressData: 'benefits-offers',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
    {
      title: 'Hagerty Valuation Tool®',
      desc: 'Data insights across vehicle values and trends.',
      linkText: 'Search vehicle values',
      link: `${process.env.HAGERTY_ROOT_URL}/valuation-tools`,
      image: '/digital-benefits-page/insights.jpg',
      modalRef: 7,
      cypressData: 'benefits-insights',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
    {
      title: 'Original content',
      desc: 'Digital content not available anywhere else.',
      linkText: 'View media',
      link: `${process.env.HAGERTY_ROOT_URL}/media/videos/`,
      image: '/digital-benefits-page/curated.jpg',
      modalRef: 9,
      cypressData: 'benefits-curated',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
    {
      title: hasAskHagertyFeature ? 'Ask Hagerty' : 'Ask Hagerty help desk',
      desc: 'Get help finding parts, shops, services, and more.',
      linkText: hasAskHagertyFeature ? 'Get help now' : 'Connect with expert',
      link: hasAskHagertyFeature
        ? `${process.env.HAGERTY_ROOT_URL}/drivers-club/member/ask-hagerty/`
        : 'mailto:askhagerty@hagerty.com',
      image: '/digital-benefits-page/experts.jpg',
      isTargetBlank: false,
      modalRef: 11,
      cypressData: 'benefits-experts',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
    {
      title: 'Drivers Club Magazine',
      desc: 'Six issues per year of our award-winning magazine.',
      image: '/digital-benefits-page/magazine.jpg',
      modalRef: 6,
      cypressData: 'benefits-magazine',
      isReferral: false,
      hasModal: false,
      modalContent: null,
    },
  ];

  const referralContent = {
    title: 'Hagerty Referrals',
    desc: 'Refer your friends and earn cash off at The Shop.',
    image: '/digital-benefits-page/referral.jpg',
    linkText: 'Refer a friend',
    link: '',
    modalRef: 0,
    cypressData: 'benefits-referral',
    isReferral: true,
    hasModal: true,
    modalContent: {
      image: {
        url: '/digital-benefits-page/referral.jpg',
        alt: 'Car enthusiasts',
      },
      title: 'Share the passion',
      body: (
        <>
          <p>
            Know a friend who loves cars as much as you? Refer them to Hagerty and get entered to win an automotive
            photo shoot with a professional photographer. Give your car the star treatment.
          </p>
          <p>Plus, you and anyone who you refer will automatically get $5 off at The Shop.</p>
        </>
      ),
    },
  };

  if (hasHdcReferral) {
    staticContent.splice(2, 0, staticContent[5]);
    staticContent[6] = referralContent;
  }

  const i18n = {
    nextIssue: 'benefits.magazine.next-issue',
    mailingDate: 'benefits.magazine.mailing-date',
    magazineUrl: 'benefits.magazine.url',
    memberVotingTitle: 'benefits.member-voting.title',
    memberVotingSubheading: 'benefits.member-voting.subheading',
    memberVotingCtaText: 'benefits.member-voting.cta-text',
    memberVotingCtaLink: 'benefits.member-voting.cta-link',
  };

  const { t } = useTranslation();
  const magazineUrl = t(i18n.magazineUrl);
  const MAGAZINE_LINK_TEXT = 'Read your Digital Magazine';

  const handlePageInteraction = (actionName: string) => {
    trackInteraction('click', actionName);
  };

  const renderDigitalBenefitBoxes = () => {
    return staticContent.map((item, i) => {
      if (i === 0) {
        return (
          <div
            className={cx('digital-benefits-page__grid-item', {
              'digital-benefits-page__grid-item--non-member': !membership,
            })}
            key={i}
          >
            <MembershipCard profile={profile} membership={membership} hasLegacyMembership={hasLegacyMembership} />
          </div>
        );
      }
      if (!membership) {
        return (
          <div
            className="digital-benefits-page__grid-item digital-benefits-page__grid-item--non-member"
            key={item.title}
            data-cy={item.cypressData}
          >
            <DigitalBenefitCard
              title={item.title}
              description={item.desc}
              ctaText={item.linkText}
              ctaHref={item.link}
              backgroundImage={item.image}
            />
          </div>
        );
      }

      if (i === 1) {
        return (
          <div
            className={`digital-benefits-page__grid-item ${
              !hasHdcReferral && 'digital-benefits-page__grid-item--expanded'
            }`}
            data-cy={item.cypressData}
            key={item.title}
          >
            <DigitalBenefitCard
              title={item.title}
              description={item.desc}
              ctaText={item.linkText}
              ctaHref={item.link}
              backgroundImage={item.image}
              isAppLink={item.isAppLink}
              isMagazineCard={!hasHdcReferral}
              hasModal={item.hasModal}
              modalContent={item.modalContent}
              roadsideContent={item.roadsideContent}
              id={item.cypressData}
            >
              {!hasHdcReferral && (
                <>
                  <div className="digital-benefit-card__info-box-wrap">
                    <div className="digital-benefit-card__text-icon">
                      <a
                        href="tel:888-310-8020"
                        aria-label="Phone"
                        onClick={() => handlePageInteraction('Call 888-310-8020 for service')}
                        data-cy="benefits-roadside-phone"
                      >
                        <img className="icon" src={callIcon} alt="call" />
                        Call 888-310-8020 for service
                      </a>
                    </div>
                    <div className="digital-benefit-card__text-icon">
                      <a
                        href={item.roadsideManualUrl}
                        aria-label="Download"
                        target="__blank"
                        download
                        onClick={() => handlePageInteraction('Download roadside manual')}
                        data-cy="benefits-roadside-manual"
                      >
                        <img className="icon" src={downloadIcon} alt="download" />
                        Download roadside manual
                      </a>
                    </div>
                    <div className="digital-benefit-card__info-box digital-benefit-card__info-box--full">
                      <Translate resourceKey="Your roadside plan" />
                      <p>
                        <Translate resourceKey="Premier roadside service, with guaranteed flatbed towing, real-time service truck tracking and an unlimited number of service calls." />
                      </p>
                    </div>
                    <div className="digital-benefit-card__info-box digital-benefit-card__info-box--half">
                      <Translate resourceKey="Miles per tow" />
                      <p>
                        <span>{membership?.mileage?.towingDistance || 0} miles</span>
                      </p>
                    </div>
                    <div className="digital-benefit-card__info-box digital-benefit-card__info-box--half">
                      <Translate resourceKey="benefits.emergency.services" />
                      <p>
                        {/* Convert amountCovered cents value to dollars */}
                        <span>
                          ${membership?.mileage?.amountCovered ? membership?.mileage?.amountCovered / 100 : 100} towards
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="digital-benefit-card__info-box">
                    <Translate resourceKey="Covered" />
                    <p>
                      <Translate resourceKey="benefits.vehicles.singleTier" />
                    </p>
                  </div>
                </>
              )}
            </DigitalBenefitCard>
          </div>
        );
      }
      if (i === staticContent.length - 1) {
        return (
          <div className="digital-benefits-page__grid-item" data-cy="benefits-card" key={item.title}>
            <DigitalBenefitCard
              title={staticContent[i].title}
              description={staticContent[i].desc}
              backgroundImage={staticContent[i].image}
              isMagazineCard
              data-cy={item.cypressData}
            >
              <div className="digital-benefit-card__info-box-wrap">
                <div className="digital-benefit-card__info-box digital-benefit-card__info-box--half">
                  <Translate resourceKey="Next issue" />
                  <p>
                    <Translate resourceKey={i18n.nextIssue} />
                  </p>
                </div>
                <div className="digital-benefit-card__info-box digital-benefit-card__info-box--half">
                  <Translate resourceKey="Mailing date" />
                  <p>
                    <Translate resourceKey={i18n.mailingDate} />
                  </p>
                </div>
                <div className="digital-benefit-card__more mb-4">
                  {hasMagazineLink && (
                    <a
                      aria-label={MAGAZINE_LINK_TEXT}
                      href={magazineUrl}
                      target="_blank"
                      onClick={() => trackInteraction('link', MAGAZINE_LINK_TEXT)}
                    >
                      {MAGAZINE_LINK_TEXT}
                      <svg className="icon">
                        <use xlinkHref="#16-arrow-right" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </DigitalBenefitCard>
          </div>
        );
      } else {
        return (
          <div className="digital-benefits-page__grid-item" data-cy={item.cypressData} key={item.title}>
            <DigitalBenefitCard
              title={staticContent[i].title}
              description={staticContent[i].desc}
              ctaText={staticContent[i].linkText}
              ctaHref={staticContent[i].link}
              backgroundImage={staticContent[i].image}
              isAppLink={staticContent[i].isAppLink}
              isTargetBlank={staticContent[i].isTargetBlank}
              isReferral={staticContent[i]?.isReferral}
              hasModal={staticContent[i].hasModal}
              modalContent={staticContent[i].modalContent}
              id={staticContent[i].cypressData}
            />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    if (ready && isAuthenticated && !membership) {
      navigate('/redemption', { replace: true });
    }
  }, [ready, isAuthenticated, membership]);

  const sidekickRef = useRef(null);

  useEffect(() => {
    if (sidekickRef && ready && hasMemberVoting) {
      const listener = (event: any) => {
        if (event.target.classList.contains('hg-button') && trackInteraction) {
          trackInteraction('button', 'Vote Now');
        }
      };

      sidekickRef?.current.addEventListener('click', listener);

      return () => {
        sidekickRef?.current.removeEventListener('click', listener);
      };
    }
  }, [ready, hasMemberVoting, sidekickRef, trackInteraction]);

  if (!ready || !isAuthenticated || !splitIsReady) {
    const height = isBrowser ? (window.innerHeight > 440 ? window.innerHeight - 400 : 40) : 40;
    return <LoadingContainer height={height} />;
  }

  return (
    <>
      <Helmet>
        {hasMemberFeedbackSurvey && (
          <script src={withPrefix('/benefits-page/qualtrics-survey.js')} type="text/javascript" />
        )}
        {hasHdcReferral && <script src={withPrefix('/benefits-page/referral-sdk.js')} type="text/javascript" />}
      </Helmet>

      <FadeIn duration={0.3}>
        <div className="digital-benefits-page">
          <div className="digital-benefits-page__inner">
            <div className="digital-benefits-page__heading" data-cy="benefits-heading">
              <Translate className="digital-benefits-overline" as="h1" resourceKey="benefits.banner.overline" />
              {!membership ? (
                <>
                  <h2 className="text-display_2">Get more with membership</h2>
                  <Translate resourceKey="benefits.more.description.singleTier" as="p" />
                  <Link to={linkTo} className="button__primary" data-cy="benefits-upgrade">
                    Upgrade now
                  </Link>
                </>
              ) : (
                <h2 className="text-display_2">Your membership benefits</h2>
              )}
              {isShelbyCustomer && <StateFarmLogo className="position" />}
            </div>
            {hasMemberVoting && (
              <div className="digital-benefits-page__sidekick mb-5" ref={sidekickRef}>
                <Sidekick
                  buttonLabel={t(i18n.memberVotingCtaText)}
                  href={t(i18n.memberVotingCtaLink)}
                  background={{ img: { src: bannerBackground } }}
                >
                  <div>
                    <h2 className="text-display_1 color-white pt-0">{t(i18n.memberVotingTitle)}</h2>
                    <h4 className="py-0" dangerouslySetInnerHTML={{ __html: t(i18n.memberVotingSubheading) }} />
                  </div>
                </Sidekick>
              </div>
            )}
            <div
              className={`digital-benefits-page__grid-container ${
                !hasHdcReferral && 'digital-benefits-page__grid-container--fixed-height'
              }`}
            >
              {renderDigitalBenefitBoxes()}
            </div>
          </div>
          <div className="inset-l" />

          <MainFooter />
        </div>
      </FadeIn>
      <WelcomeModal />
      <div id="ZN_50vjcoTSLRGByGa">{/* DO NOT REMOVE - QUALTRICS SURVEY CONTENTS PLACED HERE */}</div>
    </>
  );
};

export default DigitalBenefits;
